import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';
import SyccureWorks from '../../components/syccure-works/syccure-works';
import Contact from '../../components/contact/contact';
import Solution from '../../assets/images/solution-bg.jpg';
import iconBlogChaine from '../../assets/images/icon-block-chain.png';
import iconTemplates from '../../assets/images/icon-templates.png';
import iconPolicy from '../../assets/images/icon-policy.png';
import screenShotTellus from '../../assets/images/screenshot-tell-us.jpg';
import screenShotPolicies from '../../assets/images/screenshot-policies.jpg';
import screenShotProduct from '../../assets/images/screenshot-product-dashboard.jpg';
import './index.scss';
import Layout from '../../layout';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Slide1 from '../../assets/images/slide-1.jpg';
const { marketingWebUrl } = process.env;
const ogImageUrl = marketingWebUrl + Slide1;
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {

  return (
    <Layout>
      <Helmet title="Solution | Authorization Re-Imagined">
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content={ogImageUrl} />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Solution | Authorization Re-Imagined"
        />
        <meta property="og:description" content="Authorization Re-Imagined" />
        <meta property="og:url" content={marketingWebUrl} />
        <meta property="og:site_name" content="Syccure" />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Header headerClass={'header haeder-soltion'}></Header>
      <BannerInner
        bannerImg={Solution}
        bannerTitle="I T simplified"
      ></BannerInner>
      <Container>
        <div className="iconContent iconContentBig text-center">
          <Row>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={iconBlogChaine} alt="icon blog chain" />
                <div className="icontext">
                  <h3>Blockchain based</h3>
                  <p>granular visibility and non-intrusive audits</p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={iconTemplates} alt="icon templates" />
                <div className="icontext">
                  <h3>Simple Templates</h3>
                  <p>Zero Trust security in minutes – not days</p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="iconCol text-center">
                <img src={iconPolicy} alt="icon policy" />
                <div className="icontext">
                  <h3>Policy as Code</h3>
                  <p>move fast, be safe, stay compliant</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>


      </Container>

      <div className="alt-bg-linear">
        <Container>
          <div className="iconContent iconContentBig text-center">
            <Row>
              <Col md="4" data-sal="fade-in" data-sal-duration="1000" data-sal-easing="ease">
                <div className="iconCol text-center no-margin-top " >
                  <div className="icontext">
                    <p>log in and tell us about your company...</p>
                  </div>
                  <img
                    src={screenShotTellus}
                    alt="screenshot tell us about your company"
                  />
                </div>
              </Col>
              <Col md="4" data-sal="fade-in" data-sal-duration="1000" data-sal-easing="ease" data-sal-delay="500">
                <div className="iconCol text-center no-margin-top">
                  <div className="icontext">
                    <p>import or create policies to get started...</p>
                  </div>
                  <img src={screenShotPolicies} alt="screenshot policies" />
                </div>
              </Col>
              <Col md="4" data-sal="fade-in" data-sal-duration="1000" data-sal-easing="ease" data-sal-delay="1000">
                <div className="iconCol text-center no-margin-top">
                  <div className="icontext">
                    <p>test, deploy, manage and share policies...</p>
                  </div>
                  <img
                    src={screenShotProduct}
                    alt="screenshot product dashboard"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="btn-center mar-top-3rem">
                  <div className="text-center">
                    <Link className="btn btn-primary" to="/contact">
                      GET DEMO
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Contact></Contact>
      <Footer></Footer>
    </Layout>
  );
}

export default Index;
