import React from 'react';
import './banner-inner.scss';

const BannerInner = (props) => (
  <div className="bannerHolder bannerInner">
    <div className="carousel-item">
      <img src={props.bannerImg} alt="Slide" />
      <div className="carousel-caption">
        <div className="container">
          <div className="content">
            <h3>{props.bannerTitle}</h3>
            <h6>{props.bannerDesc}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BannerInner;
